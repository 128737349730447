import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDashboardTotals } from '../services/api';
import '../css/Dashboard.css';

const Dashboard = () => {
    const [totals, setTotals] = useState({
        total_notificacoes: 0,
        total_eventos: 0,
        total_compromissos: 0,
    });

    useEffect(() => {
        const fetchTotals = async () => {
            try {
                const data = await getDashboardTotals();
                setTotals(data);
            } catch (error) {
                console.error('Erro ao buscar totais:', error);
            }
        };

        fetchTotals();
    }, []);

    return (
        <div className="dashboard">
            <div className="card">
                <h3>Intimações</h3>
                <p>{totals.total_notificacoes} hoje</p>
                <Link to="/intimacoes">Ver Intimações</Link>
            </div>
            <div className="card">
                <h3>Movimentações</h3>
                <p>{totals.total_eventos} hoje</p>
                <Link to="/movimentacoes">Ver Movimentações</Link>
            </div>
            <div className="card">
                <h3>Processos</h3>
                <p>Cadastre seus processos</p>
                <Link to="/processos">Ver Processos</Link>
            </div>
            <div className="card">
                <h3>Agenda</h3>
                <p>{totals.total_compromissos} compromissos hoje</p>
                <Link to="/compromissos">Ver Agenda</Link>
            </div>
            <div className="card">
                <h3>Prazos</h3>
                <p>Hoje: 0</p>
                <p>Próximos 7 dias: 0</p>
                <p>Próximos 15 dias: 0</p>
            </div>
        </div>
    );
};

export default Dashboard;
